@import "colours";

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin-top: 24px;
  list-style-type: none;

  @media (min-width: 768px) {
    margin-top: 32px;
  }

  &__pagination-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border: 1px solid $dullerBlue;
    box-sizing: border-box;
    border-radius: 5px;

    @media (min-width: 768px) {
      margin: 0 auto;
    }

    &--active {
      background: $dullerBlue;
      border: 1px solid $dullerBlue;
      color: $text;
    }

    &--previous {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border: 1px solid $dullerBlue;
      box-sizing: border-box;
      border-radius: 5px;
      margin-right: 20px;

      @media (min-width: 768px) {
        margin-right: 200px;
      }

      @media (min-width: 1024px) {
        margin-right: 320px;
      }

      @media (min-width: 1300px) {
        margin-right: 440px;
      }
    }

    &--next {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border: 1px solid $dullerBlue;
      box-sizing: border-box;
      border-radius: 5px;
      margin-left: 20px;

      @media (min-width: 768px) {
        margin-left: 200px;
      }

      @media (min-width: 1024px) {
        margin-left: 320px;
      }

      @media (min-width: 1300px) {
        margin-left: 440px;
      }
    }
  }

  &__pagination-link {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: $dullBlue;
    padding: 10px 15px;
    cursor: pointer;

    &--break {
      margin: 5px;
      color: $dullBlue;
    }
  }
}